<template>
  <div class="news-page">
    <layout :header="$t('newsMenu')" @goback="$router.push('/')">
      <template v-slot:content>
        <v-col class="card-news" cols="12" xs="12" sm="6" md="4" lg="3" xl="2" v-for="i in 10" :key="i+'item'" >
          <v-card outlined class="rounded-lg" @click="$router.push('/NewsDetail/1')">
            <v-img
              class="white--text align-end"
              :src="`/Image/mock${i % 4 + 1}.jpg`"
              height="200px"
              cover
            ></v-img>
          </v-card>
           <div class="title-card">
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
              mollit anim. Hey, don't cut me off like that.
            </div>
        </v-col>
      </template>
    </layout>
  </div>
</template>

<script>
import Layout from "../../components/layout_1";
export default {
  name: "News",
  components: {
    layout: Layout,
  },
};
</script>

<style lang="scss">
.news-page {
  height: 100%;
  .card-news {
    position: relative;
  }

  .title-card {
    position: absolute;
    bottom: 16px;
    padding: 0px 16px;
    color: white;
    font-size: 19px;
    font-weight: 800;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
</style>